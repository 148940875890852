<template>
    <div>
        <b-row style="margin-bottom:-5px;" v-if="items.length >0">
            <b-col md="3" sm="4" class="mb-1">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50"></label>
                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                </b-form-group>
            </b-col>
            <b-col md="3" sm="8" class="my-1">

            </b-col>
            <b-col md="3" class=''>
                <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                    class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="3">
                <a :href="base_url + 'download/laporan/tervalidasi/'+rs_periode_pembayaran.id" target="_blank">
                    <b-button block variant="primary" size="sm">
                        <feather-icon icon="DownloadIcon" size="15" /> Download Laporan
                    </b-button>
                </a>
            </b-col>
        </b-row>
        <b-row>

            <b-col cols="12" class="mt-1">
                <b-table thead-class="hidden_header" striped hover responsive :per-page="perPage"
                    :current-page="currentPage" :items="items" :fields="fields" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template #cell(tagihan)="data">

                        <b-row class="">
                            <b-col md="2">
                                <b-alert variant="primary" show style="margin-left:-15px;">
                                    <div class="alert-body">
                                        <small>No Tagihan</small>
                                        <h5 class="text-primary" style="margin-bottom:-9px;">{{ data.item.no_inv}}
                                        </h5>
                                        <h6 class=" mt-1"><small>Tgl Tagihan </small><br>{{ data.item.date_c}} </h6>
                                    </div>
                                </b-alert>
                            </b-col>
                            <b-col md="4" class="match-height">
                                <small>Nim/ Nama</small><br>
                                <h6>{{ data.item.nim }} <br><small> Nama</small><br> {{ data.item.nm_lengkap }}</h6>
                                <h6>Kelas {{ data.item.kelas }}, Tahun Angkatan {{ data.item.th_angkatan }}</h6>

                            </b-col>

                            <b-col md="3">
                                <small class="text-warning">Tertagih</small><br>
                                <h2 class="text-warning"><sup><small>Rp.
                                        </small></sup><strong>{{ Rp(data.item.tertagih) }}</strong></h2>
                                <small class="text-primary">Terbayar</small><br>
                                <h2 class="text-primary"><sup><small>Rp.
                                        </small></sup><strong>{{Rp(data.item.terbayar)   }}</strong></h2>
                            </b-col>
                            <b-col md="3" class="match-height">
                                <small>Tanggal Bayar Bank</small><br>
                                <h6>{{ data.item.tgl_pembayaran }}</h6>
                                <small>Validator</small><br>
                                <h6>{{data.item.user  }} | {{ data.item.tgl }}</h6>
                                <h5 class="text-primary">
                                    <feather-icon icon="CheckSquareIcon" size="15" /> Status Pembayaran Tervalidasi</h5>

                            </b-col>
                        </b-row>

                        <!-- {{ data.item }} -->
                    </template>
                </b-table>
            </b-col>
            <div v-if="items.length < 1" class="text-center">
                <h5 class="text-center text-warning ml-3">***Maaf... Belum ada transaksi tervalidasi yang bisa kami
                    sajikan</h5>
            </div>
            <b-col cols="12" v-if="items.length > 0">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import Base from '@/config/Mixins_base'
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BAlert,
        BCard
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BAlert,
            BCard
        },
        props: {
            items: [],
            rs_periode_pembayaran: {}
        },
        mixins: [Base],
        data() {
            return {
                base_url: localStorage.getItem('base_url'),
                perPage: 5000,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],

                fields: [{
                    key: 'tagihan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }, ],


            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>