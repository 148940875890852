<template>
    <div class="row">
        <div class="col-12 ">
            <div class="card card-statistics">
                <div class="card-body  ">
                    <b-row>
                        <b-col class="mb-1" style="margin-top:-10px;">
                            <h5 class="text-primary">
                                <feather-icon icon="GridIcon" size="20" /> {{ rs_periode_pembayaran.ket }} , nominal
                                tagihan <sup>Rp. </sup>{{Rp( rekening[0].tagihan) }}</h5>
                        </b-col>
                    </b-row>
                    <div class="row">
                        <div class="col-xl-2 col-sm-6 col-12 mb-2 mb-sm-0">
                            <div class="media">
                                <b-avatar rounded="" size="50" variant="light-secondary" class="mr-1">
                                    <feather-icon icon="UploadCloudIcon" size="30" />
                                </b-avatar>
                                <div class="media-body my-auto">
                                    <h3 class="font-weight-bolder text-secondary mb-0">{{ statistik.total }}</h3>
                                    <p class="card-text font-small-3 text-secondary mb-0"> Pengajuan</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-2 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                                <b-avatar rounded="" size="50" variant="light-secondary" class="mr-1">
                                    <feather-icon icon="AirplayIcon" size="30" />
                                </b-avatar>
                                <div class="media-body my-auto">
                                    <h3 class="font-weight-bolder text-secondary mb-0">{{ statistik.konformasi }}</h3>
                                    <p class="card-text font-small-3 text-secondary mb-0">Konfirmasi </p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-xl-2 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                                <b-avatar rounded="" size="50" variant="light-danger" class="mr-1">
                                    <feather-icon icon="XCircleIcon" size="30" />
                                </b-avatar>
                                <div class="media-body my-auto">
                                    <h3 class="font-weight-bolder mb-0">10</h3>
                                    <p class="card-text font-small-3 mb-0">Ditolak/Tidak Valid</p>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-xl-2 col-sm-6 col-12 mb-2 mb-sm-0">
                            <div class="media">
                                <b-avatar rounded="" size="50" variant="light-info" class="mr-1">
                                    <feather-icon icon="CheckCircleIcon" size="30" />
                                </b-avatar>
                                <div class="media-body my-auto">
                                    <h3 class="font-weight-bolder text-info mb-0">{{ statistik.valid }}</h3>
                                    <p class="card-text font-small-3 text-info mb-0">Tervalidasi</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                                <b-avatar rounded="" size="50" variant="warning" class="mr-1">
                                    <feather-icon icon="StarIcon" size="30" />
                                </b-avatar>
                                <div class="media-body my-auto">
                                    <h3 class="font-weight-bolder text-warning mb-0"><sup><small>Rp.
                                            </small></sup>{{ Rp(statistik.dana.tertagih) }}</h3>
                                    <small class="card-text text-warning font-small-3 mb-0">Total Tertagih
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                                <b-avatar rounded="" size="50" variant="primary" class="mr-1">
                                    <feather-icon icon="StarIcon" size="30" />
                                </b-avatar>
                                <div class="media-body my-auto">
                                    <h3 class="font-weight-bolder text-primary mb-0"><sup><small>Rp.
                                            </small></sup>{{ Rp(statistik.dana.terbayar) }}</h3>
                                    <small class="card-text text-primary font-small-3 mb-0">Total Terbayar
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-row class="mt-1">
                        <b-col md="6">
                        </b-col>
                        <b-col md="6">
                            Rekening Bank - {{ rekening[0].bank }}, {{ rekening[0].no_rekening }} atas nama
                            {{  rekening[0].atas_nama}}
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BFormGroup,
        BAvatar
    } from 'bootstrap-vue'
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BFormGroup,
            BAvatar
        },
        props: {
            rekening: {},
            rs_periode_pembayaran: {},
            statistik: {}
        },
        methods: {
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
        }

    }
</script>

<style>

</style>