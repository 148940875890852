<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-form-group style="margin-top:-10px;margin-bottom: -10px;">
            <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="ket"
              @input="load_transksi_tervalidasi()" :options="option" />
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <statistk :rekening=rekening :rs_periode_pembayaran=rs_periode_pembayaran :statistik=statistik></statistk>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <daftar-tagihan :items=items :rs_periode_pembayaran=rs_periode_pembayaran></daftar-tagihan>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
  import Base from '@/config/Mixins_base'
  import axios from '@/config/Axios'
  import {
    BRow,
    BCol,
    BCard,
    BFormGroup
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import statistk from './componet/statistik.vue'
  import DaftarTagihan from './componet/daftarTagihan.vue'
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      vSelect,
      BFormGroup,
      statistk,
      DaftarTagihan,
    },
    mixins: [Base],
    data() {
      return {
        selected: {
          title: 'Pilih jenis Pembayaran '
        },
        option: [],
        items: [],
        rekening: {},
        rs_periode_pembayaran: {},
        statistik: {}
      }
    },
    mounted() {
      this.periode_pembayaran();
    },
    methods: {
      async detail_periode_pembayaran() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/tagihan_tervalidasi/periode_pembayaran',
            data: {
              id_periode_pembayaran: self.selected.id_periode_pembayaran
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rs_periode_pembayaran = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async load_statistik() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/tagihan_tervalidasi/load_statistik',
            data: {
              id_periode_pembayaran: self.selected.id_periode_pembayaran
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.statistik = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async no_rekening() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/tagihan_tervalidasi/no_rekening',
            data: {
              id_periode_pembayaran: self.selected.id_periode_pembayaran
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rekening = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async load_transksi_tervalidasi() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/tagihan_tervalidasi/load_transksi_tervalidasi',
            data: {
              id_periode_pembayaran: self.selected.id_periode_pembayaran
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items = response.data.result;
            self.no_rekening();
            self.load_statistik();
            self.detail_periode_pembayaran();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async periode_pembayaran() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/tagihan_tervalidasi/periode_pembayaran',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.option = response.data.result;
            //self.rs_level = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }
  }
</script>

<style>

</style>